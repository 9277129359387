
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import http from "common/http/createHttp";

import {
  IPackageClass
} from "../../../../../../modules/product-result/data/package-result/package-result.package/package-result.package.types";
import { Getter } from "vuex-class";
import ROOT_MODULE from "../../../../../../modules/root/root.name";

@Component({})
export default class PropCreateModal extends Vue {
  @Prop(Boolean) public value: boolean;

  @Getter(`${ROOT_MODULE}/uuid`)
    uuid: string;

  get modalValue(): boolean {
    return this.value;
  }

  set modalValue(value: boolean) {
    this.$emit("input", value);
  }

  @Prop(Object) public product: IPackageClass;

  isSubmitingPending = false;
  proposals: any = null;

  // Define the props that this component accepts
  data: any = {};
  submited = false;

  get priceMin() {
    return this.product.content.minPrice;
  }

  get computedTotalPrice(): number {
    // Calculate the total price based on the input value
    const number = this.syncData.number;
    return number ? +(this.priceMin) + +(number) : +(this.priceMin);
  }

  @Watch("syncData.number", { deep: true, immediate: true })
  onCostChanged() {
    this.syncData.number_1 = +(this.computedTotalPrice);
  }

  get syncData() {
    return this.data;
  }

  set syncData(value) {
    this.data = value;
  }

  updateData(value: any) {
    // Update the data when the form input changes
    this.syncData = value;
  }

  async onSumbit(form$, FormData) {
    // this.isSubmitingPending = true;
    // Handle form submission
    console.log("Form submitted:", form$);
    console.log("Form data:", FormData);

    this.createProposals({
      productId: this.product.products.productsIds[0],
      agentRemarks: FormData.agentRemarks,
      uuid: this.uuid
    }).then((response) => {
      console.log("Proposal created:", response);
      this.proposals = response;
      this.submited = true;
      this.isSubmitingPending = false;
    }).catch((error) => {
      this.isSubmitingPending = false;
      console.error("Error creating proposal:", error.data);
      form$.messageBag.append(JSON.stringify(error.data?.message || error.data));
    }).finally(() => {
    });
  }

  async createProposals({
    productId,
    complectId = null,
    agentRemarks,
    uuid
  }: {productId: string, complectId?: string, agentRemarks: string, uuid: string}) {
    return http.post(`/agent/proposals/hotels/create?uuid=${uuid}`, {
      productId,
      complectId,
      agentRemarks
    }).then((response) => {
      console.log("Response:", response);
      return response;
    }).catch((error) => {
      console.error("Error:", error);
      throw error;
    });
  }

  async updateProposals({
    productId,
    proposalId,
    complectId = null,
    agentRemarks,
    markupAmount,
    uuid
  }: {productId: string, proposalId: string, complectId?: string, agentRemarks: string, uuid: string, markupAmount: string}) {
    return http.post(`/agent/proposals/hotels/update?uuid=${uuid}`, {
      productId,
      proposalId,
      complectId,
      markupAmount,
      agentRemarks
    }).then((response) => {
      console.log("Response:", response);
      return response;
    }).catch((error) => {
      console.error("Error:", error);
      throw error;
    });
  }
}
