
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import TlIcon from "ui-modules/icons/icon";
import { isCancel } from "axios";
import { TranslateResult } from "vue-i18n";
import { PropType } from "vue/types/v3-component-props";

@Component({
  methods: { isCancel },
  components: {
    TlIcon
  }
})
export default class PopupUniversal extends Vue {
  @Prop({
    type: Boolean,
    default: true
  }) isCancelable?: boolean;

  @Prop({
    type: Boolean,
    default: true
  }) isFooter?: boolean;

  @Prop(String)
    className!: string;

  @Prop()
    popupStyles: Record<string, string>

  @Prop({ required: true })
    title!: string | TranslateResult;

  @Prop({ type: Boolean, default: false })
    isB2b: boolean;

  defaultStyles: Record<string, string> = {
    "margin": "auto",
    "top": "50%",
    "transform": "translateY(-50%)"
  };

  get uid() {
    // uniq vue 2 property

    return this._uid;
  }

  mounted() {
    this.$el.classList.add("modal-open");
    this.defaultStyles = {...this.defaultStyles, ...this.popupStyles}
  }

  beforeDestroy() {
    this.$el.classList.remove("modal-open");
  }
}
