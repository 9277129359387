import { Store } from "vuex";

import INSURANCE_SEARCH_MODULE from "modules/insurance-search/insurance-search.name";
import { insuranceSearchStore } from "modules/insurance-search/insurance-search.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(INSURANCE_SEARCH_MODULE, insuranceSearchStore);
};
