import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { IPostMessageState } from "../../modules/post-message/types/post-message.types";
import { IProductSearchState } from "../../modules/product-search/data/product-search.types";
import { IFlyLocationState } from "../../modules/fly-location/types/fly-location.types";
import { IFlyOrderState } from "../../modules/fly-order/types/fly-order.types";
import { IFlyResultState } from "../../modules/fly-result/types/fly-result.types";
import { IHotelLocationState } from "../../modules/hotel-location/types/hotel-location.types";
import { IFlyLocaleState } from "../../modules/i18n/types/i18n.types";
import { INotificationState } from "../../modules/notification/data/notification.types";
import { IPaymentState } from "../../modules/payment/types/payment.types";
import { MapState } from "../../modules/map/map.store";
import { IPackageOrderState } from "../../modules/product-order/data/product-order.types";
import { IPackageResultState } from "../../modules/product-result/data/product-result.types";
import { IRootState } from "../../modules/root/data/root.types";
import { ITabsState } from "../../modules/tabs/types/tabs.types";
import { IFlyProductSearchState } from "./package-app/types/package-app.types";

Vue.use(Vuex);

export interface RootState {

    state: {
        FLY_LOCATION_MODULE: IFlyLocationState
        FLY_ORDER_PRODUCT_MODULE: IFlyOrderState
        FLY_RESULT_MODULE: IFlyResultState
        FLY_SEARCH_MODULE: IFlyProductSearchState
        HOTEL_DESKTOP: any
        HOTEL_LOCATION_MODULE: IHotelLocationState
        MAP_MODULE: MapState
        NOTIFICATION_MODULE: INotificationState
        PACKAGE_DESKTOP: any
        PAYMENT_MODULE: IPaymentState
        POST_MESSAGE_MODULE: IPostMessageState
        PRODUCT_ORDER_MODULE: IPackageOrderState
        PRODUCT_RESULT_MODULE: IPackageResultState
        PRODUCT_SEARCH_MODULE: IProductSearchState
        ROOT_MODULE: IRootState
        TABS_MODULE: ITabsState
        TL_DESKTOP: any
        INSURANCE_DESKTOP: any
        i18n_MODULE: IFlyLocaleState
    }
}

// TODO: change type any for StoreOptions
const store: StoreOptions<RootState> = {
  modules: {},
  plugins: []
};

export default new Vuex.Store<RootState>(store);
