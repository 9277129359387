import http from "common/http/createHttp";
import { IInsuranceQuery } from "./data/insurance-search.types"
import { AxiosResponse } from "axios";

const beginSearch = (query: IInsuranceQuery, uuid: string): Promise<AxiosResponse> => {
  return http.post("/customer/search/insurance/request", query, { params: {uuid} });
};

const getServices = (uuid: string) => {
  return http.get("/customer/search/insurance/enabled/services", { uuid })
}

const getRegions = (uuid: string) => {
  return http.get("/customer/search/insurance/enabled/regions", { uuid })
};

export default {
  beginSearch,
  getRegions,
  getServices,
}