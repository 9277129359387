
import Vue from "vue";
import Component from "vue-class-component";
import bootstrapVue, { BootstrapVueIcons } from "bootstrap-vue";
import Toasted from "vue-toasted";
import toastOptions from "common/toasted/options";
import { Action, Getter } from "vuex-class";
import Popup from "ui-modules/popups/popup.vue";

import { ProductType, WebsiteResource } from "tl-api-doc/typescript-generator";
import {
  IFlyOrderState,
  IFlyProductSearchState,
  IOrderData
} from "applications/desktop/flight-app/types/desktop-app.types";

import DesktopApp from "applications/desktop/root-app/root-app.component.vue";

import FLY_ORDER from "modules/fly-order/fly-order.name";
import FLY_SEARCH from "modules/fly-search/fly-search.name";
import ROOT_MODULE from "modules/root/root.name";
import POST_MESSAGE_MODULE from "modules/post-message/post-message.name";
import TlIcons from "ui-modules/icons/icon";
import { ICompany } from "modules/root/data/root.types";
import AccessBtn from "applications/desktop/accessibility/AccessBtn.vue";
import AccessPopup from "applications/desktop/accessibility/AccessPopup.vue";
import { Accessibility } from "common/accessibility/accessibility";
import BuildingSearchLocaleSelector from "./flight-app/components/building-search/building-search.locale-selector.vue";
import PaymentBlockHelperPopUp from "./payments/PaymentBlockHelperPopUp.vue";
import BugReportAutomationBtn from "./BugReportAutomation/BugReportAutomationBtn.vue";
import TabsName from "../../modules/tabs/tabs.name";
import {
  closeAllTabsSessionsActionHandler,
  ITabsState,
  reloadTabsOnLoginActionHandler
} from "../../modules/tabs/types/tabs.types";
import PropCreateModal from "./hotel-app/components/result-search/ui/PropCreateModal.vue";

const DevLogin = () => NODE_ENV === "development"
  ? import(/* webpackChunkName: "dev-component" */ "applications/desktop/dev/DevLogin.vue")
  : {};

Vue.use(bootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Toasted, toastOptions);

@Component({
  components: {
    PropCreateModal,
    BugReportAutomationBtn,
    PaymentBlockHelperPopUp,
    DevLogin,
    Popup,
    AccessPopup,
    TlIcons,
    AccessBtn,
    BuildingSearchLocaleSelector,
    DesktopApp
  }
})
export default class Desktop extends Vue {
  @Getter(`${FLY_ORDER}/agencyLogoUrl`)
    agencyLogoUrl: IFlyOrderState["agencyLogoUrl"];

  @Getter(`${ROOT_MODULE}/company`)
    company: ICompany;

  @Getter(`${ROOT_MODULE}/companyId`)
    companyId: string;

  @Getter(`${ROOT_MODULE}/enabledProductTypes`)
    enabledProductTypes: ProductType[];

  @Getter(`${ROOT_MODULE}/isTerminalSearch`)
    isTerminalSearch: boolean;

  @Getter(`${ROOT_MODULE}/isB2b2c`)
    isB2b2c: boolean;

  @Getter(`${ROOT_MODULE}/isB2b`)
    isB2b: boolean;

  @Getter(`${ROOT_MODULE}/site`)
    site!: WebsiteResource;

  @Getter(`${ROOT_MODULE}/maxOrderTab`)
    maxOrderTab!: boolean;

  @Getter(`${ROOT_MODULE}/orderData`)
    orderData: IOrderData[];

  @Getter(`${TabsName}/tabs`)
    tabs: ITabsState["tabs"];

  @Getter(`${ROOT_MODULE}/uuid`)
    uuid: string;

  @Action(`${ROOT_MODULE}/toggleMaxOrderTab`)
    toggleMaxOrderTab: (toggle: boolean) => void;

  @Action(`${ROOT_MODULE}/getEnabledProductTypes`)
    getEnabledProductTypes: ({
      websiteKey,
      productType
    }: { websiteKey: string; productType?: string; }) => void;

  @Getter(`${FLY_SEARCH}/searchQuery`)
    searchQuery: IFlyProductSearchState["searchQuery"];

  @Action(`${FLY_ORDER}/getAgencyLogo`)
    getAgencyLogo: any;

  @Action(`${ROOT_MODULE}/checkSearchType`)
    checkSearchType: any;

  @Action(`${ROOT_MODULE}/loadOrderData`)
    loadOrderData: any;

  @Getter(`${ROOT_MODULE}/isAccessibility`)
    isAccessibility: boolean;

  @Action(`${ROOT_MODULE}/toggleAccessibility`)
    toggleAccessibility: (status: boolean) => Promise<void>;

  @Getter(`${ROOT_MODULE}/websiteKey`)
    websiteKey: string;

  @Action(`${POST_MESSAGE_MODULE}/catchReceiptData`)
    catchReceiptData: (payload: { type: string, data: any }) => Promise<void>;

  @Action(`${TabsName}/reloadTabsOnLogin`)
    reloadTabsOnLogin: reloadTabsOnLoginActionHandler;

  @Action(`${TabsName}/closeAllTabs`)
    closeAllTabs: closeAllTabsSessionsActionHandler;

  startAccessibility = new Accessibility();

  isPostMessageParsing = false;

  get isDev(): boolean {
    return NODE_ENV === "development";
  }

  get isLogoHidden(): boolean {
    return this.site.logoPosition === "HIDDEN";
  }

  async beforeDestroy() {
    this.togglePostMessageParsing();
  }

  togglePostMessageParsing() {
    if (this.isPostMessageParsing) {
      window.removeEventListener("message", this.parsePostMessage, false);
      this.isPostMessageParsing = !this.isPostMessageParsing;
    } else {
      window.addEventListener("message", this.parsePostMessage, false);
      this.isPostMessageParsing = !this.isPostMessageParsing;
    }
  }

  parsePostMessage(event: MessageEvent<any>) {
    try {
      const postPayload = JSON.parse(event.data);
      if (!!event.data && !!postPayload.type) {
        this.catchReceiptData(postPayload);
      }
    } catch (e) {
      // console.error(e)
    }
  }

  async created() {
    this.togglePostMessageParsing();
    const query: any = this.$route.query;
    let productType: string = null;

    const sources = document.getElementById("wmsIf97865436901");
    if (!!sources && !!sources.getAttribute("key")) {
      const key = sources.getAttribute("key");
      const locale = sources.getAttribute("locale") || "he";
      const companyId = sources.getAttribute("companyId");
      const uuid = sources.getAttribute("uuid");
      const access = sources.getAttribute("access");
      const terminalUrl = sources.getAttribute("terminalUrl");
      const appType = encodeURIComponent(sources.getAttribute("appType"));
      productType = appType;
      const searchData = encodeURIComponent(sources.getAttribute("search-data"));
      const queryFromDiv = {
        key,
        locale,
        companyId,
        uuid,
        searchData,
        access,
        appType
      };

      await this.checkSearchType(queryFromDiv);
    } else if (query) {
      productType = query.appType;
      await this.checkSearchType(query);
    } else {
      console.error("not found query");
    }

    if (this.isTerminalSearch && !!this.companyId) {
      await this.getAgencyLogo({ agencyId: this.companyId });
    }
    if (this.$route.name === "terms-of-use") {
      return;
    }
    if (this.websiteKey) {
      this.getEnabledProductTypes({
        websiteKey: this.websiteKey,
        productType
      });
    }
    if (this.uuid) {
      const isNeedBlockSelectTab = this.$route.path === "/terms-of-use";
      await this.reloadTabsOnLogin({ uuid: this.uuid, isNeedBlockSelectTab });
      await this.tryCloseAllTabs();
    }

    if (this.site.title) {
      document.title = this.site.title;
    }
  }

  async tryCloseAllTabs(): Promise<void> { // если аккаунт заблокированный сносим все вкладки
    if (this.company.status === "BLOCKED") {
      await this.redirectToHomePage();
      await this.closeAllTabs({ uuid: this.uuid });
    }
  }

  async redirectToHomePage(): Promise<void> {
    if (this.enabledProductTypes.includes("REGULAR_FLIGHT")) {
      await this.$router.push({ name: "flight-home" });
    } else if (this.enabledProductTypes.includes("STATIC_PACKAGE")) {
      await this.$router.push({ name: "package-home" });
    } else if (this.enabledProductTypes.includes("HOTEL")) {
      await this.$router.push({ name: "hotel-home" });
    }
  }
}
