import http from "common/http/createHttp";
import Vue from "vue";

import DesktopEntry from "applications/desktop/desktop.entry";
import Desktop from "applications/desktop/desktop.vue";
import i18n from "applications/desktop/i18n";
import router from "applications/desktop/router";
import store from "applications/desktop/store";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import PortalVue from "portal-vue";
import Vueform from '@vueform/vueform'
import vueformConfig from './vueform.config'

// router.beforeEach((to, from, next) => {
//   if (to.path === from.path) {
//     next(false);
//   } else {
//     next();
//   }
// });

// Sentry.init(
//   {
//     Vue,
//     dsn: "https://8139ed1bf0f04bf1887e4d41d7602a3b@o1126534.ingest.sentry.io/6167455",
//     integrations: [
//       new Sentry.BrowserTracing(
//         {
//           routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//           tracePropagationTargets: [
//             "beeasy.pro",
//             "dev1.tripsoft.pro",
//             "dev2.tripsoft.pro",
//             "tripsoft.pro",
//             "t.beeasy.pro",
//             /^\//
//           ]
//         }),
//       new Sentry.Replay()
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//     replaysSessionSampleRate: 0.1,
//     // If the entire session is not sampled, use the below sample rate to sample
//     // sessions when an error occurs.
//     replaysOnErrorSampleRate: 1.0
//   });

Vue.use(PortalVue);

declare const VUE_APP_CURRENT_GIT_SHA: string;
const APP_VERSION = VUE_APP_CURRENT_GIT_SHA;
if (typeof localStorage.APP_VERSION === "undefined" || localStorage.APP_VERSION === null) {
  localStorage.setItem("APP_VERSION", APP_VERSION);
}

if (localStorage.APP_VERSION !== APP_VERSION) {
  // localStorage.clear();
}

type D = Icon.Default & {
    _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
  iconUrl: require("leaflet/dist/images/marker-icon.png").default,
  shadowUrl: require("leaflet/dist/images/marker-shadow.png").default
});

DesktopEntry({ store, router, http, i18n });

Vue.use(Vueform, vueformConfig);

const vue = new Vue({
  el: "#app",
  i18n,
  render: h => h(Desktop),
  router,
  store
});
