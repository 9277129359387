var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{attrs:{"hide-footer":""},model:{value:(_vm.modalValue),callback:function ($$v) {_vm.modalValue=$$v},expression:"modalValue"}},[(_vm.isSubmitingPending)?_c('div',{staticClass:"m-n3 flex-fill w-100 text-center overf"},[_c('b-spinner',{staticStyle:{"width":"155px","height":"155px"},attrs:{"label":"Small Spinner","variant":""}})],1):_vm._e(),_vm._v(" "),_c('Vueform',{ref:"form",attrs:{"sync":"","endpoint":false,"value":_vm.syncData,"size":"md"},on:{"input":_vm.updateData,"submit":_vm.onSumbit}},[_c('StaticElement',{attrs:{"name":"h1","tag":"h3","content":"REFERRAL LINK  EDITOR","align":"left"}}),_vm._v(" "),_c('GridElement',{attrs:{"name":"grid","cols":2,"rows":5,"grid":[
        [
          [
            {
              name: 'number',
              type: 'text',
              inputType: 'number',
              addons: {
                before: '$',
              },
              attrs: {
                min: '-14',
              },
              rules: [
                'nullable',
                'numeric',
                `min:-${_vm.priceMin}`,
              ],
              autocomplete: 'off',
              label: 'MarkUp / Discount:',
              builder: {
                type: 'number',
                label: 'Number',
              },
            },
            2,
            1,
            'left',
            'middle',
          ],
        ],
        [
          [
            {
              name: 'number_1',
              type: 'text',
              inputType: 'number',
              rules: [
                'nullable',
                'numeric',
              ],
              autocomplete: 'off',
              label: 'Final price:',
              builder: {
                type: 'number',
                label: 'Number',
              },
              readonly: true,
              disabled: true,
            },
            2,
            1,
            null,
            null,
          ],
        ],
        [
          [
            {
              name: 'checkbox',
              type: 'checkbox',
              text: 'Show discount on promo page',
              builder: {
                type: 'checkbox',
                label: 'Decision box',
              },
              align: 'left',
              disabled: [
                [
                  [
                    'grid.number',
                    'empty',
                  ],
                  [
                    'grid.number',
                    '>=',
                    '0',
                  ],
                ],
              ],
            },
            1,
            1,
            null,
            null,
          ],
          [
            '',
            1,
            1,
            null,
            null,
          ],
        ],
        [
          [
            {
              name: 'textarea',
              type: 'textarea',
              label: 'Agent remarks:',
              builder: {
                type: 'textarea',
                label: 'Long text',
              },
            },
            2,
            1,
            null,
            null,
          ],
        ],
        [
          [
            {
              name: 'submit',
              type: 'button',
              buttonLabel: 'Save promo',
              submits: true,
              builder: {
                type: 'submit',
                label: 'Submit',
              },
              full: true,
            },
            1,
            1,
            null,
            null,
          ],
          [
            {
              name: 'submit_1',
              type: 'button',
              buttonLabel: 'Copy promo link',
              builder: {
                type: 'submit',
                label: 'Submit',
              },
              full: true,
              disabled: !_vm.submited,
              onClick: () => {_vm.onCopyClick()}
            },
            1,
            1,
            null,
            null,
          ],
        ],
      ],"widths":[
        '243px',
        null,
      ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }