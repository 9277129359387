import Query from "modules/product-search/data/product-search.query/product-search.query.class";
import { IPackageQueryClass, IQuery, ISearchSegment } from "modules/product-search/data/product-search.types";
import { IConvertedHotelQuery } from "../product-search.query/product-search.query.types";

export default class PackageQuery extends Query implements IPackageQueryClass {
  constructor(private _packageQuery: IQuery) {
    super(_packageQuery);
  }

  getFirstSegment(): ISearchSegment {
    const segment: ISearchSegment = {
      ...super.getFirstSegment()
    };
    return super.getFirstSegment(segment);
  }

  convertToRequest(): IConvertedHotelQuery {
    return {
      rooms: this.rooms,
      rating: this.rating,
      residency: this.residency?.code,
      paxes: this.paxes,
      rawSegments: this.segments,
      segments: [
        this.convertSegment(this.getFirstSegment()),
        this.convertSegment(this.getSecondSegment())
      ]
    };
  }
}
