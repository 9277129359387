
import { Component, Vue, Watch } from "vue-property-decorator";
import SearchWithOrderPending from "../../../ui-modules/SearchWithOrderPending/index.vue";
import { Getter } from "vuex-class";
import ROOT_MODULE from "../../../modules/root/root.name";
import { ProductType } from "tl-api-doc/typescript-generator";
import TabsName from "../../../modules/tabs/tabs.name";
import { ITab } from "../../../modules/tabs/types/tabs.types";

@Component({
  components: { SearchWithOrderPending }
})
export default class StartPage extends Vue {
  @Getter(`${ROOT_MODULE}/displayTab`)
    displayTab: {
    flight: boolean,
    package: boolean,
    hotel: boolean,
      car: boolean
  };

  @Getter(`${ROOT_MODULE}/enabledProductTypes`)
    enabledProductTypes: ProductType[];

  @Getter(`${TabsName}/selectedTab`)
    selectedTab: ITab;

  get randNameBackgroundImg(): string {
    const minCount = 0;
    const maxCount = 6;
    return `bgflt${Math.floor(
      Math.random() * (maxCount - minCount + 1) + minCount
    )}.jpg`;
  }

  async goToAvailableStartPage() {
    // if empty enabledProductTypes stay here
    if (!this.enabledProductTypes?.length) return;

    if (this.selectedTab && this.selectedTab.activePath && this.selectedTab.activePath !== "/") {
      await this.$router.push(this.selectedTab.activePath);
      return;
    }

    if (this.enabledProductTypes.includes("REGULAR_FLIGHT")) {
      await this.$router.push({ name: "flight-home" });
    } else if (this.enabledProductTypes.includes("STATIC_PACKAGE")) {
      await this.$router.push({ name: "package-home" });
    } else if (this.enabledProductTypes.includes("HOTEL")) {
      await this.$router.push({ name: "hotel-home" });
    } else if (this.enabledProductTypes.includes("TRAVEL_INSURANCE")) {
      await this.$router.push({ name: "insurance-home" });
    }
  }

  created() {
    this.goToAvailableStartPage();
  }

  @Watch("enabledProductTypes")
  onChangeEnabledProductTypes() {
    this.goToAvailableStartPage();
  }
}
