import { Store } from "vuex";

import INSURANCE_RESULT_MODULE from "modules/insurance-result/insurance-result.name";
import { insuranceResultStore } from "modules/insurance-result/insurance-result.store";

export default (context: { store: Store<null>; http: any }): void => {
  const { store } = context;

  store.registerModule(INSURANCE_RESULT_MODULE, insuranceResultStore);
};
