import {
  IConvertedQuery,
  IConvertedSearchSegment,
  IPaxes,
  IQuery,
  IQueryClass,
  ISearchSegment
} from "modules/product-search/data/product-search.types";
import { IATARegionResource } from "tl-api-doc/typescript-generator";
import { IRoom } from "./product-search.query.types";

export default abstract class Query implements IQueryClass {
  protected constructor(private readonly _query: IQuery) {
    this._query = _query;
  }

  get residency(): IATARegionResource {
    return this._query.residency;
  }

  set residency(residency) {
    this._query.residency = residency;
  }

  get rooms(): IQuery["rooms"] {
    return this._query.rooms;
  }

  set rooms(rooms: IRoom[]) {
    this._query.rooms = rooms;
  }

  addEmptyRoom() {
    this._query.rooms.push({
      adults: 1,
      childAges: []
    });
  }

  get content(): IQuery {
    return this._query;
  }

  get entityId(): IQuery["entityId"] {
    return this._query.entityId;
  }

  get segments(): ISearchSegment[] {
    return this._query.segments;
  }

  get paxes(): IPaxes {
    return this.content.paxes;
  }

  set entityId(value: IQuery["entityId"]) {
    this._query.entityId = value;
  }

  set paxes(value: IPaxes) {
    this._query.paxes = value;
  }

  set segments(value: ISearchSegment[]) {
    this._query.segments = value;
  }

  get rating(): IQuery['rating'] {
    return this._query.rating
  }

  set rating(value: IQuery['rating']) {
    this._query.rating = value
  }

  getFirstSegment(existSegment?: ISearchSegment): ISearchSegment {
    if (existSegment) {
      return existSegment;
    }

    return this.segments && this.segments[0] ? this.segments[0] : null;
  }

  getSecondSegment(existSegment?: ISearchSegment): ISearchSegment {
    if (existSegment) {
      return existSegment;
    }

    return this.segments && this.segments[1] ? this.segments[1] : null;
  }

  convertSegment(segment: ISearchSegment): IConvertedSearchSegment {
    return {
      ...segment,
      fromAirport: segment.fromAirport ? segment.fromAirport.code : null,
      toAirport: segment.toAirport ? segment.toAirport.code : null
    };
  }

  convertToRequest(): IConvertedQuery {
    return {
      residency: this.residency?.code,
      paxes: this._query.paxes,
      segments: [
        this.convertSegment(this.getFirstSegment()),
        this.convertSegment(this.getSecondSegment())
      ]
    };
  }
}
