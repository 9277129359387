import http from "common/http/createHttp";
import { AxiosResponse } from "axios";

const getSearchResults = (requestId: string, uuid: string): Promise<AxiosResponse> => {
  return http.get(`/customer/search/insurance/requests/${requestId}/results`, { uuid, useLastFilter: false });
};

const getPrices = (
  requestId: string, 
  productIds: string[]
): Promise<AxiosResponse> => {
  return http.get(`/customer/search/insurance/requests/${requestId}/prices?${encodeURIComponent("productIds[]")}=${productIds}`);
};

export default {
  getSearchResults,
  getPrices,
}