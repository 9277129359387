import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import actionHelper from "utils/actionHelper";
import api from "modules/insurance-result/insurance-result.api";
import { RootState } from "../../applications/desktop/store";
import { IInsuranceResultState } from "./data/insurance-result.types";
import { poll } from "common/polling";
import INSURANCE_SEARCH_MODULE from "modules/insurance-search/insurance-search.name";
import ROOT_MODULE from "modules/root/root.name"
import { ApiBaseSataPackageResource } from "common/types/types";

const namespaced = true;

const RUN_INSURANCE_SEARCH = actionHelper("RUN_INSURANCE_SEARCH");
const GET_PRICES = actionHelper("GET_PRICES");

const state: IInsuranceResultState = {
  errorGetPrices: null,
  errorRunInsuranceSearch: null,
  isPending: {
    getPrices: false,
    getSearchResults: false,
    runInsuranceSearch: false,
  },
  prices: null,
  searchResults: null
}

const getters: GetterTree<IInsuranceResultState, RootState> = {
  isPending: (state: IInsuranceResultState) => state.isPending,
  prices: (state: IInsuranceResultState) => state.prices,
  searchResults: (state: IInsuranceResultState) => state.searchResults,
}

const actions: ActionTree<IInsuranceResultState, RootState> = {
  async getSearchResults(
    { commit },
    { requestId, uuid }: { requestId: string, uuid: string }
  ): Promise<ApiBaseSataPackageResource> {
    return (await api.getSearchResults(requestId, uuid)).data
  },

  async runSearchResultsPolling(
    { dispatch },
    { requestId, uuid }: { requestId: string, uuid: string }
  ): Promise<ApiBaseSataPackageResource> {

    async function getSearchResults(): Promise<ApiBaseSataPackageResource> {
      return dispatch(
        "getSearchResults",
        { requestId, uuid }
      );
    }

    function validation(result: ApiBaseSataPackageResource): boolean {
      // console.log("runSearchResultsPolling, validation result:", result);
      return !result.page.query.pendingConnectors.length
    }

    return poll(getSearchResults, validation, 20, 1000);
  },

  async runInsuranceSearch({ commit, dispatch, rootGetters }) {
    commit(RUN_INSURANCE_SEARCH.INITIAL)
    try {
      const requestId = rootGetters[`${INSURANCE_SEARCH_MODULE}/searchRequestId`]
      const results = await dispatch(
        "runSearchResultsPolling",
        { requestId, uuid: rootGetters[`${ROOT_MODULE}/uuid`] }
      );
      commit(RUN_INSURANCE_SEARCH.SUCCEEDED, results)
    }
    catch (error) {
      commit(RUN_INSURANCE_SEARCH.FAILED, error)
    }
  },

  async getPrices(
    { commit, rootGetters, state }
  ) {
    commit(GET_PRICES.INITIAL)
    try {
      const requestId = rootGetters[`${INSURANCE_SEARCH_MODULE}/searchRequestId`]
      const productIds = state.searchResults.content.map(product => product.productId)
      const result = (await api.getPrices(requestId, productIds)).data
      commit(GET_PRICES.SUCCEEDED, result)
      return result
    }
    catch(e) {
      commit(GET_PRICES.FAILED, e)
    }
  }
}

const mutations: MutationTree<IInsuranceResultState> = {
  [RUN_INSURANCE_SEARCH.INITIAL](state) {
    state.isPending.runInsuranceSearch = true
    state.searchResults = null
  },
  [RUN_INSURANCE_SEARCH.SUCCEEDED](state, result) {
    state.isPending.runInsuranceSearch = false
    state.searchResults = result
  },
  [RUN_INSURANCE_SEARCH.FAILED](state, error) {
    state.isPending.runInsuranceSearch = false
    state.errorRunInsuranceSearch = error
  },

  [GET_PRICES.INITIAL](state) {
    state.isPending.getPrices = true
    state.prices = []
  },
  [GET_PRICES.SUCCEEDED](state, result) {
    state.isPending.getPrices = false
    state.prices = result
  },
  [GET_PRICES.FAILED](state, error) {
    state.isPending.getPrices = false
    state.errorGetPrices = error
  },
}

export const insuranceResultStore: Module<IInsuranceResultState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
}
