import { IPaxesMaxRange } from "../../fly-search/types/fly-search.types";

const MAX_TOTAL_PAX = 9;

const FLIGHT_CLASSES: string[] = [
  "ECONOMY",
  "FIRST",
  "BUSINESS",
  "PREMIUM_ECONOMY"
];

const PAX: IPaxesMaxRange = {
  ADULT: [0, 9],
  CHILD: [0, 9],
  INFANT: [0, 9],
  SENIOR: [0, 9]
};

enum QueryTypes {
  "package" = "PACKAGE",
  "flight" = "FLIGHT",
  "hotel" = "HOTEL",
  "insurance" = "INSURANCE"
}

enum FlightTypes {
  roundtrip = "ROUNDTRIP",
  oneway = "ONEWAY",
  multiCity = "MULTICITY",
}

export { MAX_TOTAL_PAX, FLIGHT_CLASSES, PAX, QueryTypes, FlightTypes };
