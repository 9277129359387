import { IPaxesMaxRange } from "./fly-search.types";

export default abstract class SearchingData {
  readonly maxTotalPax: number = 9;
  readonly flightTypes: string[] = ["ROUNDTRIP", "ONEWAY", "MULTICITY"];
  readonly flightClassesList: string[] = [
    "ECONOMY",
    "FIRST",
    "BUSINESS",
    "PREMIUM_ECONOMY"
  ];

  readonly flightTypesCategory: string[] = [
    "REGULAR",
    "CHARTER"
  ];

  readonly flightTypesCategoryTemp: string[] = [
    "REGULAR",
    "CHARTER"
  ];

  pax: IPaxesMaxRange = {
    ADULT: [0, 9],
    CHILD: [0, 9],
    INFANT: [0, 9],
    SENIOR: [0, 9]
  };
}
