import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import actionHelper from "utils/actionHelper";
import api from "modules/insurance-search/insurance-search.api";
import { RootState } from "../../applications/desktop/store";
import { IInsuranceQuery, IInsuranceSearchState } from "./data/insurance-search.types";
import { IInsurancePax, ISearchSimpleFormInputs } from "applications/desktop/insurance-app/components/building-search/validate/building-search.simple-form-fields.validate";
import ROOT_MODULE from "modules/root/root.name";
import PRODUCT_SEARCH from "modules/product-search/product-search.name";
import { QueryTypes } from "modules/product-search/data/product-search.consts";

const namespaced = true;

const BEGIN_SEARCH = actionHelper("BEGIN_SEARCH");
const BEGIN_INSURANCE_SEARCH = actionHelper("BEGIN_INSURANCE_SEARCH");
const GET_REGIONS = actionHelper("GET_REGIONS");
const GET_SERVICES = actionHelper("GET_SERVICES");
const INIT_SEARCH_QUERY = "INIT_SEARCH_QUERY"
const SAVE_SEARCH_QUERY = "SAVE_SEARCH_QUERY"

const state: IInsuranceSearchState = {
  isPending: {
    beginSearch: false,
    beginInsuranceSearch: false,
    getRegions: false,
    getServices: false,
  },
  resultOfBeginSearch: null,
  regions: [],
  services: [],
  searchQuery: null,
}

const getters: GetterTree<IInsuranceSearchState, RootState> = {
  isPending: (state: IInsuranceSearchState) => state.isPending,
  regions: (state: IInsuranceSearchState) => state.regions,
  services: (state: IInsuranceSearchState) => state.services,
  resultOfBeginSearch: (state: IInsuranceSearchState) => state.resultOfBeginSearch,
  searchQuery: (state: IInsuranceSearchState) => state.searchQuery,
  searchRequestId: (state: IInsuranceSearchState): string => state.resultOfBeginSearch.requestId,
}

const actions: ActionTree<IInsuranceSearchState, RootState> = {
  async beginInsuranceSearch(
    { commit, dispatch, rootGetters },
    { params }: { params: ISearchSimpleFormInputs }
  ) {
    commit(BEGIN_INSURANCE_SEARCH.INITIAL)

    try {
      const query = {
        arrivalDate: params.dateRange.to,
        departureDate: params.dateRange.from,
        region: params.region[0],
        // websiteType: rootGetters[`${ROOT_MODULE}/isTerminalSearch`] ? "AGENCY_TERMINAL" : "AGENCY_SHOWCASE",
        websiteType: "BACKOFFICE_SHOWCASE", // DEBUG !
        customers: params.paxes
      }

      // Remove it ???
      // dispatch(`${PRODUCT_SEARCH}/saveQuery`, 
      //   { query, queryType: QueryTypes.insurance },
      //   { root: true }
      // );

      await dispatch('beginSearch', {
        uuid: rootGetters[`${ROOT_MODULE}/uuid`],
        query: query
      })

      commit(BEGIN_INSURANCE_SEARCH.SUCCEEDED)
    }
    catch {
      commit(BEGIN_INSURANCE_SEARCH.FAILED)
    }
  },

  async beginSearch({ commit }, {uuid, query}: {uuid: string, query: IInsuranceQuery} ) {
    commit(BEGIN_SEARCH.INITIAL)

    try {
      const result = await api.beginSearch(query, uuid)
      commit(BEGIN_SEARCH.SUCCEEDED, result)
      return result
    }
    catch(error) {
      commit(BEGIN_SEARCH.FAILED)
    }
  },

  async getRegions({ commit }, { uuid }: {uuid: string} ) {
    try {
      commit(GET_REGIONS.INITIAL)
      commit(
        GET_REGIONS.SUCCEEDED,
        await api.getRegions(uuid)
      )
    }
    catch(error) {
      commit(GET_REGIONS.FAILED, error)
    }
  },

  async getServices({ commit }, { uuid }: {uuid: string} ) {
    try {
      commit(GET_SERVICES.INITIAL)
      commit(
        GET_SERVICES.SUCCEEDED,
        await api.getServices(uuid)
      )
    }
    catch(error) {
      commit(GET_SERVICES.FAILED, error)
    }
  }
}

const mutations: MutationTree<IInsuranceSearchState> = {
  [BEGIN_INSURANCE_SEARCH.INITIAL](state) {
    state.isPending.beginInsuranceSearch = true
    state.resultOfBeginSearch = null
  },
  [BEGIN_INSURANCE_SEARCH.SUCCEEDED](state) {
    state.isPending.beginInsuranceSearch = false
  },
  [BEGIN_INSURANCE_SEARCH.FAILED](state, error) {
    state.isPending.beginInsuranceSearch = false
  },

  [BEGIN_SEARCH.INITIAL](state) {
    state.isPending.beginSearch = true
  },
  [BEGIN_SEARCH.SUCCEEDED](state, response) {
    state.isPending.beginSearch = false
    state.resultOfBeginSearch = response.data
  },
  [BEGIN_SEARCH.FAILED](state, error) {
    state.isPending.beginSearch = false
  },

  [GET_REGIONS.INITIAL](state) {
    state.isPending.getRegions = true
    state.regions = []
  },
  [GET_REGIONS.SUCCEEDED](state, response) {
    state.regions = response.data
    state.isPending.getRegions = false
  },
  [GET_REGIONS.FAILED](state, error) {
    state.isPending.getRegions = false
  },

  [GET_SERVICES.INITIAL](state) {
    state.isPending.getServices = true
    state.services = []
  },
  [GET_SERVICES.SUCCEEDED](state, response) {
    state.services = response.data
    state.isPending.getServices = false
  },
  [GET_SERVICES.FAILED](state, error) {
    state.isPending.getServices = false
  },

  [INIT_SEARCH_QUERY](state, query: IInsuranceQuery) {
    state.searchQuery = {
      dateRange: {
        from: null,
        to: null
      },
      paxes: [
        {age: 18, services: []},
      ],
      region: ['EUROPE'],
      isAgree: false,
      isConfirm: false,
    }
    // console.log('INIT_SEARCH_QUERY', state.searchQuery)
  },
  [SAVE_SEARCH_QUERY](state, query: IInsuranceQuery) {
    state.searchQuery = query
  }
}

export const insuranceSearchStore: Module<IInsuranceSearchState, RootState> = {
  actions,
  getters,
  mutations,
  namespaced,
  state
}
